<template>

	<div 
		:id="'player-' + player.id"
		class="player-row tm-tr"
		:class="get_classes"
		ref="row"
	>

		<div class="tr-main tm-tr">

			<div class="main-left">

				<div class="team-logo tm-td"  @click="select">
					<div class="logo"><img :src="require( '@/assets/img/logos/' + player.vue.team_logo )"></div>
				</div>

				<div class="player-fullname tm-td" @click="player.advanced = !player.advanced">
					<div>
						{{ get_mobile_fullname( player ) }} <em v-if="player.ttfl.b2b">B2B</em>
					</div>
				</div>

				<div class="status tm-td">
					<div :class="'injury ' + player_injury.class" :title="player_injury.details"></div>
				</div>

			</div>

			<div class="main-right">

				<div class="season-average average score tm-td">
					<span :style="'opacity:' + player.vue.ttfl_av_color.opacity" :class="'ttfl-score_bg ' + player.vue.ttfl_av_color.class"></span>
					<span class="ttfl-score">{{ player.ttfl.avg_season }}</span>
				</div>

				<div class="momentum average score tm-td">
					<span :style="'opacity:' + player.ttfl.mom.opacity" :class="'ttfl-score_bg ' + player.ttfl.mom.class"></span>
					<span class="ttfl-score">{{ player.ttfl.mom.value }}</span>
				</div>

				<div class="estimation average score tm-td">
					<span :style="'opacity:' + player.ttfl.estimation.opacity" :class="'ttfl-score_bg ' + player.ttfl.estimation.class"></span>
					<span class="ttfl-score">{{ player.ttfl.estimation.value }}</span>
				</div>

				<div class="index score tm-td">
					<span :style="'opacity:' + player.ttfl.index.opacity" :class="'ttfl-score_bg ' + player.ttfl.index.class"></span>
					<span class="ttfl-score">{{ player.ttfl.index.value }}</span>
				</div>

				<div class="action tm-td">

					<button class="btn-pick btn-action" v-if="get_user_id() && player.availability === true" @click="pick">
						<font-awesome-icon :icon="['fas', 'user-check']" />
					</button>
					<button class="btn-pick btn-action" v-else-if="get_user_id() && player.availability !== true">
						<span>J-{{ player.availability }}</span>
					</button>
					<button class="disabled btn-pick btn-action" v-else>
						<font-awesome-icon :icon="['fas', 'user-slash']" />
					</button>

				</div>

			</div>

			<div class="advanced" v-if="player.advanced"><div>

				<mobile-block-advanced :player="player">

					<template slot="block-head">

						<div>
							<div class="th-title">Stats +</div>
						</div>

						<div>
							<div class="score">10J</div>
							<div class="score">30J</div>
							<div class="score">B2B</div>
							<div class="score">H/A</div>
							<div class="score">STB</div>
						</div>

					</template>

					<template slot="block-body">

						<div></div>
						
						<div>

							<div class="average score">
								<span :style="'opacity:' + player.vue.ttfl_l10_av_color.opacity" :class="'ttfl-score_bg ' + player.vue.ttfl_l10_av_color.class"></span>
								<span class="ttfl-score">{{ player.ttfl.last_10days.avg }}</span>
							</div>

							<div>
								<div class="average score">
									<span :style="'opacity:' + player.vue.ttfl_l30_av_color.opacity" :class="'ttfl-score_bg ' + player.vue.ttfl_l30_av_color.class"></span>
									<span class="ttfl-score">{{ player.ttfl.last_30days.avg }}</span>
								</div>
							</div>

							<div class="average score">
								<span :style="'opacity:' + player.ttfl.index_b2b.opacity" :class="'ttfl-score_bg ' + player.ttfl.index_b2b.class"></span>
								<span class="ttfl-score">{{ player.ttfl.index_b2b.value }}</span>
							</div>

							<div class="average score">
								<span :style="'opacity:' + player.ttfl.homeaway.opacity" :class="'ttfl-score_bg ' + player.ttfl.homeaway.class + ' ' + ( player.ttfl.home_game === true ? 'home' : 'away' )"></span>
								<span class="ttfl-score">{{ player.ttfl.homeaway.value }}</span>
							</div>
							
							<div class="stab index score">
								<span :style="'opacity:' + player.ttfl.index_stab.opacity" :class="'ttfl-score_bg ' + player.ttfl.index_stab.class"></span>
								<span class="ttfl-score">{{ player.ttfl.index_stab.value }}</span>
							</div>

						</div>

					</template>

				</mobile-block-advanced>

				<mobile-block-advanced :player="player">
					
					<template slot="block-head">

						<div>
							<div class="th-title score">
								<span v-if="player.vue.home_game">VS</span>
								<span v-else>@</span>
							</div>
							<div>3 derniers VS</div>
						</div>

						<div>
							<div class="score">IDX</div>
							<div class="score">DEF</div>
						</div>

					</template>

					<template slot="block-body">

						<div>
							<div class="team-logo team_opp-logo">
								<div class="logo"><img :src="require( '@/assets/img/logos/' + player.vue.team_vs_logo )"></div>
							</div>

							<div class="games_historical">

								<div class="scores-list">
									<a :href="game_url( game.game_url )" target="_blank" v-for="( game, key ) in player.ttfl.last_3vs.games" v-bind:key="key" class="score">
										<span :style="'opacity:' + game.ttfl.opacity" :class="'ttfl-score_bg ' + game.ttfl.class"></span>
										<span :title="game.date" class="ttfl-score">{{ game.ttfl_score }}</span>
									</a>
								</div>

							</div>
						</div>

						<div>
							<div class="average score">
								<span :style="'opacity:' + player.ttfl.index_vs.opacity" :class="'ttfl-score_bg ' + player.ttfl.index_vs.class"></span>
								<span class="ttfl-score">{{ player.ttfl.index_vs.value }}</span>
							</div>

							<div class="average score">
								<span :style="'opacity:' + player.ttfl.index_def.opacity" :class="'ttfl-score_bg ' + player.ttfl.index_def.class"></span>
								<span class="ttfl-score">{{ player.ttfl.index_def.value }}</span>
							</div>
						</div>
						
					</template>

				</mobile-block-advanced>

				<mobile-block-advanced :player="player">

					<template slot="block-head">

						<div>
							<div class="th-title">5 derniers</div>
						</div>

					</template>

					<template slot="block-body">

						<div>
							<a :href="game_url( game.game_url )" target="_blank" v-for="( game, key ) in player.ttfl.last_5.games" v-bind:key="key" class="score">
								<span :style="'opacity:' + game.ttfl.opacity" :class="'ttfl-score_bg ' + game.ttfl.class"></span>
								<span :title="game.date" class="ttfl-score">{{ game.ttfl_score }}</span>
							</a>
						</div>

					</template>

				</mobile-block-advanced>

				<mobile-block-advanced :player="player" class="heat-map">

					<template slot="block-head">

						<div>
							<div class="th-title">Heat map</div>
						</div>

						<div>
							<div class="score">&lt; 20</div>
							<div class="score">&lt; 30</div>
							<div class="score">&lt; 40</div>
							<div class="score">&lt; 50</div>
							<div class="score">&gt; 50</div>
						</div>

					</template>

					<template slot="block-body">

						<div>
							<div class="score"><em>30J</em></div>
						</div>

						<div>

							<div v-for="( score, key ) in player.ttfl.heat_map" v-bind:key="key" class="score">
								<span :style="'opacity:' + score.opacity" :class="'ttfl-score_bg ' + score.class"></span>
								<span class="ttfl-score">
									{{ score.float_value }}
								</span>
							</div>

						</div>

					</template>

				</mobile-block-advanced>

				<mobile-block-advanced
					:player="player"
					:compare="compare"
					v-if="user_picks.length > 0"
				>

					<template slot="block-head">

						<div>
							<div class="th-title">{{ user_pseudo }}</div>
						</div>

					</template>

					<template slot="block-body">

						<div>

							<a
								v-for="( game, key ) in user_picks"
								v-bind:key="key"
								:href="game_url( game.game_url )"
								target="_blank"
								class="score"
							>
								<span :style="'opacity:' + game.ttfl.opacity" :class="'ttfl-score_bg ' + game.ttfl.class"></span>
								<span class="ttfl-score">
									{{ game.ttfl_score }}
								</span>
							</a>

						</div>

					</template>

				</mobile-block-advanced>

				<mobile-block-advanced
					:player="player"
					:compare="compare"
					class="compare"
				>

					<template slot="block-head">

						<div>
							<div class="th-title">Coéquipier</div>
						</div>

						<div><div>

							<button @click="change_without" :class="{ without : compare.without }">
								{{ 'Sans' }}
							</button>

							<vselect
								ref="user_select"
								id="user-select"
								:options="get_mates_options()"
								:select="player.player_id"
								:onchange="change_mate"
								:placeholder="'Coéquipier'"
							/>

						</div></div>

					</template>

					<template slot="block-body">

						<div>

							<a :href="game_url( game.game_url )" target="_blank" v-for="( game, key ) in compare.games" v-bind:key="key" class="score">
								<span :style="'opacity:' + game.ttfl.opacity" :class="'ttfl-score_bg ' + game.ttfl.class"></span>
								<span class="ttfl-score">
									{{ game.ttfl_score }}
								</span>
							</a>

						</div>

					</template>

				</mobile-block-advanced>

				<div class="upcomings" v-if="player.upcoming_games">
					
					<div class="block-title">À VENIR</div>

					<mobile-block-advanced
						v-for="( game, key ) in player.upcoming_games"
						v-bind:key="key"
					>
						
						<template slot="block-head">

							<div>
								<div class="th-title">{{ game.date }}</div>
								<div>3 derniers VS</div>
							</div>

							<div>
								<div class="score">IDX</div>
								<div class="score">DEF</div>
								<div class="score">H/A</div>
								<div class="score">EST</div>
							</div>

						</template>

						<template slot="block-body">

							<div>

								<div class="team-logo team_opp-logo">

									<span v-if="game.home_game">VS</span>
									<span v-else>@</span>

									<div class="logo">
										<img v-if="game.team_opp.logo != ''" :src="require( '@/assets/img/logos/' + game.team_opp.logo )">
									</div>

								</div>

								<div class="games_historical">

									<div class="scores-list">

										<a
											v-for="( game, key ) in game.last_3vs.games"
											:key="key"
											:href="game_url( game.game_url )"
											target="_blank"
											class="score"
										>
											<span :style="'opacity:' + game.ttfl_score.opacity" :class="'ttfl-score_bg ' + game.ttfl_score.class"></span>
											<span :title="game.date" class="ttfl-score">{{ game.ttfl_score.value }}</span>
										</a>
										
									</div>

								</div>

							</div>

							<div>

								<div class="average score">

									<span :style="'opacity:' + game.index_vs.opacity" :class="'ttfl-score_bg ' + game.index_vs.class"></span>
									<span class="ttfl-score">
										{{ game.index_vs.value }}
									</span>

								</div>

								<div class="average score">

									<span :style="'opacity:' + game.index_def.opacity" :class="'ttfl-score_bg ' + game.index_def.class"></span>
									<span class="ttfl-score">
										{{ game.index_def.value }}
									</span>

								</div>

								<div class="average score">

									<span :style="'opacity:' + game.index_homeaway.opacity" :class="'ttfl-score_bg ' + game.index_homeaway.class"></span>
									<span class="ttfl-score">
										{{ game.index_homeaway.value }}
									</span>

								</div>

								<div class="score">

									<span :style="'opacity:' + game.estimation.opacity" :class="'ttfl-score_bg ' + game.estimation.class"></span>
									<span class="ttfl-score">
										{{ game.estimation.value }}
									</span>

								</div>
								
							</div>
							
						</template>

					</mobile-block-advanced>

				</div>

				<button class="close" @click="player.advanced = !player.advanced">Fermer</button>

			</div></div>

		</div>

	</div>

</template>

<script>

	import { library } from '@fortawesome/fontawesome-svg-core'
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
	import { faMinus, faPlus, faUserCheck, faUserSlash } from '@fortawesome/free-solid-svg-icons'
	library.add( faMinus, faPlus, faUserCheck, faUserSlash )

	import mixin_device from '@/mixins/device'
	import mixin_tools from '@/mixins/tools'
	import mixin_player from '@/mixins/player'

	export default {
		
		name : 'player_row_mobile',

		components: {
			
			'font-awesome-icon' : FontAwesomeIcon,
			vselect	: () => import( '@/components/form/vselect' ),

			mobileBlockAdvanced : () => import( '@/components/player/block-mobile-adv.vue' ),
			
		},

		mixins	: [ mixin_device, mixin_tools, mixin_player ],

		props : [ 'player' ],

		data	: function() { return {

			// advanced	: false,

			// compare	: {

			// 	without	: true,
			// 	mate_id	: false,
			// 	mate	: false,
			// 	games	: [],

			// },
			
		}},

		methods	: {

			/**
			 */

			get_user_id() {
				return this.$store.state.user.id
			},

		},

		watch	: {
		},

		computed	: {

			/**
			 */

			player_store() {
				return this.$store.getters['players/get_player']( this.player.id )
			},

			/**
			 */

			player_injury() {
				return this.player.injury
			},

		},

		mounted	: function() {
		},

	}

</script>

<style lang="scss" scoped>

	@import '@/assets/scss/_base';

	@import '@/assets/scss/table-mobile';



	$bgcolor	: lighten( #000, 96% );

	/** ---------------------------------------*\
	*---------------------------------------- **/

	.tm-tr {

		position: relative;
		@include flex_display;
		align-items: stretch;
		flex: 100%;
		width: 100%;

		.logo {

			width: 20px;
			height: 20px;
			object-fit: contain;

		}

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	.tm-tr.player-row {

		flex-flow: row wrap;
		border-bottom: 1px solid #dee2e6;

		&.pick_unavailable {
			opacity: 0.5;
		}

		&.pick_today {
			background: transparentize( $green, 0.8 );
		}

		&.selected {
			background-color: transparentize( $color: $purple, $amount: 0.6 );
		}

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	.tm-tr.tr-main {

		justify-content: space-between;

		& > div {
			@include flex_display;
		}

		& > div.main-left {
			flex: auto;
			max-width: calc( 100% - 200px );
		}

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	.tm-tr .tm-td {

		@include flex_display;
		align-items: center;
		padding: 5px;
		font-size: 0.875em;

		&.compare {

			position: absolute;
			opacity: 0;
			pointer-events: none;

		}

		&.player-fullname {

			font-size: 0.7em;
			line-height: 1em;

			em {
				font-size: 0.8em;
			}

		}

		&.status {

			width: 10px;
			height: 10px;
			padding: 0;
			margin-left: auto;
			margin-right: 15px;

			div.injury {
				display: inline-block;
				vertical-align: middle;
				width: 100%;
				height: 100%;
				margin: 0;
			}

			div.injury.notready {
				background-color: rgba( 0, 0, 0, 0.2 );
			}

			div.injury.healthy {
				background-color: $green;
			}

			div.injury.out {
				background-color: $red;
			}

			div.injury.gtd {
				background-color: $orange;
			}

		}

		&.score,
		&.action {

			width: 40px;
			height: 100%;
			min-height: 40px;
			padding: 0;
			background-color: #FFF;

		}

		&.score {

			width: 40px;
			height: 100%;
			min-height: 40px;
			padding: 0;

			span.ttfl-score {
				@include flex_display;
				justify-content: center;
				align-items: center;
				font-size: 1em;
			}

		}

		&.action button {

			width: 100%;
			height: 100%;
			margin: 0;

			svg {
				width: calc( 100% - 25px );
				height: calc( 100% - 25px );
				margin: 12.5px;
			}

		}

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	.advanced {

		flex: 100%;
		max-width: 100%;
		padding: 0;
		background-color: #FFF;

		& > div {
			@include flex_display;
			max-width: 100%;
			overflow: hidden;
			border: 2px solid #000;
		}

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	#players .advanced .upcomings {

		width: 100%;
		border: 4px solid $red;
		background: $red;

		.loader {

			padding: 0 10px 10px;
			background: $red;
			color: #FFF;
			font-style: italic;
			text-transform: uppercase;
			font-size: 0.8em;

			animation: loader 1.2s $ease-inout-expo infinite;

		}

		@keyframes loader {
			0% { opacity: 0; }
			50% { opacity: 1; }
			100% { opacity: 0; }
		}

		.block-title {

			padding: 15px 10px;
			background: $red;
			color: #FFF;
			font-size: 1.2em;
			font-weight: bold;
			
		}

		.block {

			background: #FFF;

			.th-title,
			.block-body .team-logo {
				width: 60px;
			}

			.block-body .team-logo {
				
				span {
					margin-right: 10px;
					font-weight: bold;
					font-size: 0.65em;
					line-height: 1em;
				}

			}

			.score {

				@include iphone5() {
					width: 33px;
				}

			}

			.ttfl-score {
				
				font-size: 0.85em;
				
				@include iphone5() {
					font-size: 0.8em;
				}
			}

		}

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/
	
	.advanced .close {

		flex: 100%;
		max-width: 100%;
		height: 40px;
		border: 0;
		background-color: #000;
		color: #FFF;

	}

</style>